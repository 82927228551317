<template>
  <div class="user">
    <div class="title">
      <h1>{{ $route.meta.title }}</h1>
      <span></span>
    </div>
    <div class="content">
      <ul class="left" v-if="logoutShow">
        <li
          @click="changePage(item)"
          :class="item.router == glID ? 'ligl' : ''"
          v-for="item in leftMenu"
          :key="item.id"
        >
          {{ item.name }}
        </li>
        <li @click="logOut()">退出登录</li>
      </ul>
      <el-tabs v-else v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="个人信息" name="UserInfo"></el-tab-pane>
        <el-tab-pane label="参赛作品" name="userWork"></el-tab-pane>
      </el-tabs>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
    <footerCard class="footer" :workstate="workstate"></footerCard>
    <el-dialog
      class="mydialog"
      title="入群请添加客服"
      :visible.sync="WechatShow"
      :width="dialogwidth"
      :before-close="
        () => {
          this.WechatShow = false;
        }
      "
    >
      <span><img src="../../assets/image/wechat.jpg" alt="" /></span>
    </el-dialog>
  </div>
</template>
<script>
import footerCard from "../../components/footerCard.vue";
import {
  removeToken,
  setToken,
  hasToken,
  getToken,
} from "../../assets/js/storage";
import { outLogin } from "../API/login";
import { getEntryInformation } from "../API/user";
export default {
  data() {
    return {
      leftMenu: [
        {
          id: 1,
          name: "个人信息",
          router: "UserInfo",
        },
        {
          id: 2,
          name: "参赛作品",
          router: "userWork",
        },
      ],
      activeName: "UserInfo",
      glID: this.$router.history.current.name,
      logoutShow: true,
      WechatShow: false,
      dialogwidth: "25%",
      workstate: false,
    };
  },
  created() {
    if (document.body.clientWidth < 540) {
      this.logoutShow = false;
      this.dialogwidth = "80%";
    }
    this.getUserWorkInfo();
  },
  methods: {
    handleClick() {
      this.$router.push({ name: this.activeName });
    },
    // 判断用户是否报名
    async getUserWorkInfo() {
      const { data: res } = await getEntryInformation({
        phase: localStorage.getItem("phase"),
        user_id: getToken().user_id,
      });
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      if (res.data.state !== 0) {
        this.WechatShow = true;
        this.workstate = true;
      }
    },
    changePage(item) {
      this.glID = item.router;
      this.$router.push({ name: item.router });
    },
    async logOut() {
      const { data: res } = await outLogin();
      if ((res.code = "0000")) {
        removeToken();
        this.$router.push({ name: "Login" });
        this.$message({
          message: res.msg,
          type: "success",
        });
      }
    },
  },
  components: {
    footerCard,
  },
  watch: {
    $route(go) {
      this.glID = go.name;
    },
  },
};
</script>
<style scoped lang="scss">
.user {
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  background-color: #f9f9f9;
  ::v-deep .el-tabs__item {
    font-size: 20px !important;
    @media screen and (max-width: 520px) {
      font-size: 18px !important;
      width: 50%;
      text-align: center;
    }
  }
  ::v-deep .el-tabs__nav {
    @media screen and (max-width: 520px) {
      width: 100%;
    }
  }
  ::v-deep .el-tabs__item:hover {
    color: #05258e !important;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #05258e !important;
    font-weight: 600;
  }
  ::v-deep .el-tabs__active-bar {
    background: #05258e;
  }
  ::v-deep .el-image__error {
    background: #fff;
  }
  .title {
    padding: 62px 0 28px 0;
    @media screen and (max-width: 540px) {
      padding: 30px 0;
    }
    h1 {
      font-size: 26px;
      text-align: center;
      color: #05258e;
      padding-bottom: 10px;
    }
    span {
      display: block;
      margin: 0px auto;
      width: 24px;
      height: 4px;
      background: #05258e;
      border-radius: 2px;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    padding-bottom: 150px;
    @media screen and (max-width: 540px) {
      display: block;
      padding: 0;
    }
    .left {
      padding: 30px;
      width: 390px;
      box-sizing: border-box;
      min-height: 600px;
      background-color: #fff;
      @media screen and (max-width: 540px) {
        width: 100%;
        display: flex;
        min-height: 100%;
      }
      li {
        line-height: 60px;
        background: #dce8fd;
        border-radius: 4px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        @media screen and (max-width: 540px) {
          flex: 1;
          margin: 0 3px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
      }
      .ligl {
        color: #fff;
        background-color: #05258e !important;
      }
    }
    .right {
      @media screen and (max-width: 540px) {
        width: 100%;
        margin: 0;
        padding: 20px;
        box-sizing: border-box;
      }
      width: 970px;
      margin-left: 40px;
      background-color: #fff;
      min-height: 600px;
    }
  }
  .footer {
    display: block;
    padding-top: 50px;
    @media screen and (max-width: 540px) {
    }
  }
  .mydialog {
    height: 950px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
